/* ----------------------------------------------- */
/*                    Parameters                   */
/* ----------------------------------------------- */
/* --- Col.dinosaur .bodyors --- */
/* --- Animations --- */
/* --- Fonts --- */
@font-face {
  font-family: 'Gloria Hallelujah';
  font-style: normal;
  font-weight: 400;
  src: local('Gloria Hallelujah'), local('GloriaHallelujah'), url(https://fonts.gstatic.com/s/gloriahallelujah/v12/LYjYdHv3kUk9BMV96EIswT9DIbW-MIS11zY.ttf) format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v17/S6uyw4BMUTPHjx4wWw.ttf) format('truetype');
}
/* ----------------------------------------------- */
/* ----------------------------------------------- */
html,
body {
  font-family: "Lato", sans-serif;
  color: #333333;
  background-color: white;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}
.wrapper {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-animation: position_wrapper 14s 0.1s ease-in-out infinite;
          animation: position_wrapper 14s 0.1s ease-in-out infinite;
}
.cry {
  position: absolute;
  top: 20%;
  left: 50%;
  -webkit-transform: translatex(-50%, -20%);
          transform: translatex(-50%, -20%);
  font-family: "Gloria Hallelujah", cursive;
  font-size: 30px;
  opacity: 0;
  -webkit-animation: move_cry 7s 3s infinite;
          animation: move_cry 7s 3s infinite;
}
/* .freeze::after {
  content: "Hover to calm dino";
  position: absolute;
  top: 20px;
  left: 20px;
  border: 3px solid #94a9d0;
  color: #718dc0;
  padding: 5px 10px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 20px;
  cursor: pointer;
}
.freeze:hover .cry {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.freeze:hover .wrapper {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.freeze:hover .wrapper .head {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.freeze:hover .wrapper .hand {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.freeze:hover .wrapper .hand .elbow {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.freeze:hover .wrapper .hand#second-hand {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.freeze:hover .wrapper .leg {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.freeze:hover .wrapper .leg .knee {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.freeze:hover .wrapper .leg .knee .foreleg {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.freeze:hover .wrapper .leg#second-leg {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.freeze:hover .wrapper .leg#second-leg .knee {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.freeze:hover .wrapper .leg#second-leg .knee .foreleg {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}
.freeze:hover .wrapper ul.tail {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
} */
.dinosaur {
  position: relative;
  width: 320px;
  height: 440px;
  transform: scale(0.5, 0.5);
  bottom: -300px;
}
.dinosaur .body {
  position: absolute;
  top: 200px;
  left: -320px;
  width: 85px;
  height: 180px;
  background-color: #68c3a4;
  -webkit-transform: rotate(5deg);
          transform: rotate(5deg);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}
.dinosaur .body .body-mask {
  z-index: 4;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 85px;
  height: 180px;
  background-color: #68c3a4;
  -webkit-transform: rotate(5deg);
          transform: rotate(5deg);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}
.dinosaur .body .body-mask::after {
  content: "";
  position: absolute;
  top: 45px;
  right: -30px;
  width: 80px;
  height: 100px;
  background-color: #68c3a4;
  border-radius: 50%;
}
.dinosaur .body .body-mask ul {
  list-style: none;
  position: absolute;
  top: 10px;
  left: -52px;
  width: 0px;
  height: 0px;
  -webkit-transform: rotate(-5deg);
          transform: rotate(-5deg);
}
.dinosaur .body .body-mask ul li {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 5px 0px 0px 0px;
  border-radius: 2px;
  background-color: #68c3a4;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.dinosaur .body .mark {
  z-index: 4;
  position: absolute;
  top: 30px;
  left: 10px;
  width: 30px;
  height: 40px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 25px;
  border-top-left-radius: 60%;
  border-top-right-radius: 60%;
  background-color: #47b18d;
}
.dinosaur .body .mark::after {
  content: "";
  position: absolute;
  bottom: -50px;
  left: 0px;
  width: 30px;
  height: 40px;
  border-bottom-left-radius: 60%;
  border-bottom-right-radius: 60%;
  border-top-left-radius: 25px;
  border-top-right-radius: 10px;
  background-color: #47b18d;
}
.dinosaur .body .head {
  z-index: 4;
  position: absolute;
  top: 50px;
  left: 22px;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  -webkit-transform: rotate(-120deg);
          transform: rotate(-120deg);
  width: 160px;
  height: 220px;
  background-color: #68c3a4;
  border-bottom-left-radius: 20%;
  border-bottom-right-radius: 30%;
  border-top-left-radius: 0%;
  border-top-right-radius: 30px;
}
.dinosaur .body .head::before {
  content: "";
  position: absolute;
  bottom: 0px;
  left: 18px;
  width: 70px;
  height: 120px;
  -webkit-transform: rotate(-10deg);
          transform: rotate(-10deg);
  background-color: #388d70;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 0;
  border-top-left-radius: 200%;
  border-top-right-radius: 15px;
}
.dinosaur .body .head ul {
  list-style: none;
  position: absolute;
  bottom: -44px;
  left: 44px;
  width: 0px;
  height: 140px;
  -webkit-transform: rotate(-10deg);
          transform: rotate(-10deg);
}
.dinosaur .body .head ul li {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 5px 0px 0px 0px;
  border-radius: 2px;
  background-color: #68c3a4;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.dinosaur .body .head .head-mask {
  position: absolute;
  bottom: -10px;
  left: 20px;
  width: 70px;
  height: 120px;
  -webkit-transform: rotate(-10deg);
          transform: rotate(-10deg);
  background-color: white;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 0;
  border-top-left-radius: 200%;
  border-top-right-radius: 15px;
}
.dinosaur .body .head .head-mask::before {
  content: "";
  position: absolute;
  bottom: 20px;
  left: -45px;
  width: 60px;
  height: 40px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  background-color: white;
}
.dinosaur .body .head .head-mask::after {
  content: "";
  position: absolute;
  bottom: 54px;
  left: -26px;
  width: 38px;
  height: 10px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  background-color: #68c3a4;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 0%;
  border-top-right-radius: 25px;
}
.dinosaur .body .head .eye {
  position: absolute;
  top: 65px;
  right: 15px;
  width: 45px;
  height: 55px;
  background-color:white;
  border-radius: 50%;
  -webkit-transform: rotate(-30deg);
          transform: rotate(-30deg);
  -webkit-animation: blink_eye 6s infinite;
          animation: blink_eye 6s infinite;
  overflow: hidden;
}
.dinosaur .body .head .eye::after {
  content: "";
  position: absolute;
  bottom: 15px;
  left: 10px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #333333;
}
.dinosaur .body .hand {
  z-index: 5;
  position: absolute;
  top: 0;
  right: 5px;
  width: 40px;
  height: 60px;
  background-color: #68c3a4;
  -webkit-transform-origin: top right;
          transform-origin: top right;
  -webkit-transform: rotate(-30deg);
          transform: rotate(-30deg);
  border-radius: 30px;
}
.dinosaur .body .hand .elbow {
  position: absolute;
  top: 60px;
  right: -5px;
  width: 30px;
  height: 50px;
  background-color: #68c3a4;
  -webkit-transform-origin: top left;
          transform-origin: top left;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
.dinosaur .body .hand .elbow .forearm {
  position: absolute;
  bottom: 0;
  right: -20px;
  width: 20px;
  height: 45px;
  background-color: #68c3a4;
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
  -webkit-transform: rotate(-100deg);
          transform: rotate(-100deg);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
.dinosaur .body .hand .elbow .forearm::after {
  content: "";
  position: absolute;
  top: 3px;
  right: -8px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: white;
}
.dinosaur .body .hand#second-hand {
  z-index: 2;
  top: 5px;
  right: 15px;
  width: 20px;
  height: 50px;
  -webkit-transform: rotate(-50deg);
          transform: rotate(-50deg);
  background-color: #388d70;
}
.dinosaur .body .hand#second-hand .elbow {
  width: 30px;
  height: 50px;
  background-color: #388d70;
}
.dinosaur .body .hand#second-hand .elbow .forearm {
  width: 20px;
  height: 45px;
  background-color: #388d70;
}
.dinosaur .body .leg {
  z-index: 5;
  position: absolute;
  bottom: 5px;
  right: 50px;
  width: 80px;
  height: 40px;
  background-color: #68c3a4;
  -webkit-transform-origin: center right;
          transform-origin: center right;
  -webkit-transform: rotate(200deg);
          transform: rotate(200deg);
  border-radius: 15px;
}
.dinosaur .body .leg .knee {
  position: absolute;
  top: 10px;
  left: 15px;
  width: 60px;
  height: 30px;
  background-color: #68c3a4;
  -webkit-transform-origin: center left;
          transform-origin: center left;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
.dinosaur .body .leg .knee .foreleg {
  position: absolute;
  bottom: 0;
  right: 0px;
  width: 20px;
  height: 40px;
  background-color: #68c3a4;
  border-top-left-radius: 60%;
  -webkit-transform-origin: center left;
          transform-origin: center left;
  -webkit-transform: rotate(-50deg);
          transform: rotate(-50deg);
}
.dinosaur .body .leg .knee .foreleg::after {
  content: "";
  position: absolute;
  bottom: -2px;
  right: -8px;
  width: 20px;
  height: 40px;
  border-top-left-radius: 60%;
  background-color: #dbe2ef;
}
.dinosaur .body .leg#second-leg {
  z-index: 2;
  bottom: 0px;
  right: 60px;
  width: 60px;
  height: 40px;
  -webkit-transform: rotate(200deg);
          transform: rotate(200deg);
  background-color: #388d70;
}
.dinosaur .body .leg#second-leg .knee {
  background-color: #388d70;
}
.dinosaur .body .leg#second-leg .knee .foreleg {
  background-color: #388d70;
}
.dinosaur .body ul.tail {
  list-style: none;
  position: absolute;
  bottom: -10px;
  left: 20px;
  width: 100px;
  height: 60px;
  -webkit-transform-origin: center left;
          transform-origin: center left;
  -webkit-transform: rotate(-200deg);
          transform: rotate(-200deg);
}
.dinosaur .body ul.tail li {
  position: absolute;
  background-color: #68c3a4;
}
.dinosaur .body ul.tail li::after {
  content: "";
  position: absolute;
  top: -10px;
  left: 0;
  width: 25px;
  height: 20px;
  background-color: #68c3a4;
  -webkit-transform: skew(12deg) rotate(12deg);
          transform: skew(12deg) rotate(12deg);
}
.dinosaur .body ul.tail li::before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 25px;
  height: 20px;
  background-color: #68c3a4;
  -webkit-transform: skew(-12deg) rotate(-12deg);
          transform: skew(-12deg) rotate(-12deg);
}
.dinosaur .body ul.tail li:nth-child(1) {
  left: 0;
  bottom: 0px;
  width: 25px;
  height: 50px;
}
.dinosaur .body ul.tail li:nth-child(2) {
  left: 25px;
  bottom: 5px;
  width: 25px;
  height: 40px;
}
.dinosaur .body ul.tail li:nth-child(3) {
  left: 50px;
  bottom: 10px;
  width: 25px;
  height: 30px;
}
.dinosaur .body ul.tail li:nth-child(4) {
  left: 75px;
  bottom: 15px;
  width: 25px;
  height: 20px;
}
.dinosaur .body ul.tail li:nth-child(5) {
  left: 100px;
  bottom: 20px;
  width: 25px;
  height: 10px;
}
.dinosaur .body ul.tail li:nth-child(6) {
  left: 125px;
  bottom: 13px;
  width: 0;
  height: 0;
  background-color: transparent;
  border-style: solid;
  border-width: 12px 0 12px 45px;
  border-color: transparent transparent transparent #68c3a4;
}
.dinosaur .body ul.tail li:nth-child(6)::after {
  content: none;
}
.dinosaur .body ul.tail li:nth-child(6)::before {
  content: none;
}
.dinosaur.run .body {
  -webkit-transform: rotate(25deg);
          transform: rotate(25deg);
}
.dinosaur.run .head {
  -webkit-animation: move_head 4s infinite;
          animation: move_head 4s infinite;
}
.dinosaur.run .hand {
  -webkit-animation: run_hand 2s infinite;
          animation: run_hand 2s infinite;
}
.dinosaur.run .hand#second-hand {
  -webkit-animation: run_hand-second 2s 0.5s infinite;
          animation: run_hand-second 2s 0.5s infinite;
}
.dinosaur.run .hand .elbow {
  -webkit-animation: run_elbow 2s infinite;
          animation: run_elbow 2s infinite;
}
.dinosaur.run .leg {
  -webkit-animation: run_leg 0.6s infinite;
          animation: run_leg 0.6s infinite;
}
.dinosaur.run .leg .knee {
  -webkit-animation: run_knee 0.6s infinite;
          animation: run_knee 0.6s infinite;
}
.dinosaur.run .leg .knee .foreleg {
  -webkit-animation: run_foreleg 0.6s infinite;
          animation: run_foreleg 0.6s infinite;
}
.dinosaur.run .leg#second-leg {
  bottom: 0px;
  -webkit-animation: run_leg-second 0.6s 0.3s infinite;
          animation: run_leg-second 0.6s 0.3s infinite;
}
.dinosaur.run .leg#second-leg .knee {
  -webkit-animation: run_knee-second 0.6s 0.3s infinite;
          animation: run_knee-second 0.6s 0.3s infinite;
}
.dinosaur.run .leg#second-leg .knee .foreleg {
  -webkit-animation: run_foreleg-second 0.6s 0.3s infinite;
          animation: run_foreleg-second 0.6s 0.3s infinite;
}
.dinosaur.run ul.tail {
  -webkit-animation: move_tail 0.6s infinite;
          animation: move_tail 0.6s infinite;
}
/* ----------------------------------------------- */
/*                    Keyframes                    */
/* ----------------------------------------------- */
/* --- Run Hand --- */
@-webkit-keyframes run_hand {
  0% {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
  }
  50% {
    -webkit-transform: rotate(-25deg);
            transform: rotate(-25deg);
  }
  100% {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
  }
}
@keyframes run_hand {
  0% {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
  }
  50% {
    -webkit-transform: rotate(-25deg);
            transform: rotate(-25deg);
  }
  100% {
    -webkit-transform: rotate(-30deg);
            transform: rotate(-30deg);
  }
}
@-webkit-keyframes run_elbow {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  50% {
    -webkit-transform: rotate(-75deg);
            transform: rotate(-75deg);
  }
  100% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
}
@keyframes run_elbow {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  50% {
    -webkit-transform: rotate(-75deg);
            transform: rotate(-75deg);
  }
  100% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
}
@-webkit-keyframes run_hand-second {
  0% {
    -webkit-transform: rotate(-50deg);
            transform: rotate(-50deg);
  }
  50% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  100% {
    -webkit-transform: rotate(-50deg);
            transform: rotate(-50deg);
  }
}
@keyframes run_hand-second {
  0% {
    -webkit-transform: rotate(-50deg);
            transform: rotate(-50deg);
  }
  50% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  100% {
    -webkit-transform: rotate(-50deg);
            transform: rotate(-50deg);
  }
}
/* --- Run Leg --- */
@-webkit-keyframes run_leg {
  0% {
    -webkit-transform: rotate(200deg);
            transform: rotate(200deg);
  }
  50% {
    -webkit-transform: rotate(170deg);
            transform: rotate(170deg);
  }
  100% {
    -webkit-transform: rotate(200deg);
            transform: rotate(200deg);
  }
}
@keyframes run_leg {
  0% {
    -webkit-transform: rotate(200deg);
            transform: rotate(200deg);
  }
  50% {
    -webkit-transform: rotate(170deg);
            transform: rotate(170deg);
  }
  100% {
    -webkit-transform: rotate(200deg);
            transform: rotate(200deg);
  }
}
@-webkit-keyframes run_knee {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  50% {
    -webkit-transform: rotate(-110deg);
            transform: rotate(-110deg);
  }
  100% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
}
@keyframes run_knee {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  50% {
    -webkit-transform: rotate(-110deg);
            transform: rotate(-110deg);
  }
  100% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
}
@-webkit-keyframes run_foreleg {
  0% {
    -webkit-transform: rotate(-50deg);
            transform: rotate(-50deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  100% {
    -webkit-transform: rotate(-50deg);
            transform: rotate(-50deg);
  }
}
@keyframes run_foreleg {
  0% {
    -webkit-transform: rotate(-50deg);
            transform: rotate(-50deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  100% {
    -webkit-transform: rotate(-50deg);
            transform: rotate(-50deg);
  }
}
/* --- Run Second Leg --- */
@-webkit-keyframes run_leg-second {
  0% {
    -webkit-transform: rotate(200deg);
            transform: rotate(200deg);
  }
  50% {
    -webkit-transform: rotate(170deg);
            transform: rotate(170deg);
  }
  100% {
    -webkit-transform: rotate(200deg);
            transform: rotate(200deg);
  }
}
@keyframes run_leg-second {
  0% {
    -webkit-transform: rotate(200deg);
            transform: rotate(200deg);
  }
  50% {
    -webkit-transform: rotate(170deg);
            transform: rotate(170deg);
  }
  100% {
    -webkit-transform: rotate(200deg);
            transform: rotate(200deg);
  }
}
@-webkit-keyframes run_knee-second {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  50% {
    -webkit-transform: rotate(-110deg);
            transform: rotate(-110deg);
  }
  100% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
}
@keyframes run_knee-second {
  0% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
  50% {
    -webkit-transform: rotate(-110deg);
            transform: rotate(-110deg);
  }
  100% {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
  }
}
@-webkit-keyframes run_foreleg-second {
  0% {
    -webkit-transform: rotate(-50deg);
            transform: rotate(-50deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  100% {
    -webkit-transform: rotate(-50deg);
            transform: rotate(-50deg);
  }
}
@keyframes run_foreleg-second {
  0% {
    -webkit-transform: rotate(-50deg);
            transform: rotate(-50deg);
  }
  50% {
    -webkit-transform: rotate(-10deg);
            transform: rotate(-10deg);
  }
  100% {
    -webkit-transform: rotate(-50deg);
            transform: rotate(-50deg);
  }
}
/* --- Tail Movement --- */
@-webkit-keyframes move_tail {
  0% {
    -webkit-transform: rotate(-200deg);
            transform: rotate(-200deg);
  }
  50% {
    -webkit-transform: rotate(-210deg);
            transform: rotate(-210deg);
  }
  100% {
    -webkit-transform: rotate(-200deg);
            transform: rotate(-200deg);
  }
}
@keyframes move_tail {
  0% {
    -webkit-transform: rotate(-200deg);
            transform: rotate(-200deg);
  }
  50% {
    -webkit-transform: rotate(-210deg);
            transform: rotate(-210deg);
  }
  100% {
    -webkit-transform: rotate(-200deg);
            transform: rotate(-200deg);
  }
}
/* --- Head Movement --- */
@-webkit-keyframes move_head {
  0% {
    -webkit-transform: rotate(-125deg);
            transform: rotate(-125deg);
  }
  50% {
    -webkit-transform: rotate(-110deg);
            transform: rotate(-110deg);
  }
  100% {
    -webkit-transform: rotate(-125deg);
            transform: rotate(-125deg);
  }
}
@keyframes move_head {
  0% {
    -webkit-transform: rotate(-125deg);
            transform: rotate(-125deg);
  }
  50% {
    -webkit-transform: rotate(-110deg);
            transform: rotate(-110deg);
  }
  100% {
    -webkit-transform: rotate(-125deg);
            transform: rotate(-125deg);
  }
}
/* --- Blink Eye --- */
@-webkit-keyframes blink_eye {
  0% {
    -webkit-transform: rotate(-30deg) scaleX(1);
            transform: rotate(-30deg) scaleX(1);
  }
  1% {
    -webkit-transform: rotate(-30deg) scaleX(0);
            transform: rotate(-30deg) scaleX(0);
  }
  3% {
    -webkit-transform: rotate(-30deg) scaleX(1);
            transform: rotate(-30deg) scaleX(1);
  }
}
@keyframes blink_eye {
  0% {
    -webkit-transform: rotate(-30deg) scaleX(1);
            transform: rotate(-30deg) scaleX(1);
  }
  1% {
    -webkit-transform: rotate(-30deg) scaleX(0);
            transform: rotate(-30deg) scaleX(0);
  }
  3% {
    -webkit-transform: rotate(-30deg) scaleX(1);
            transform: rotate(-30deg) scaleX(1);
  }
}
/* --- Appear Text --- */
@-webkit-keyframes move_cry {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    opacity: 0;
  }
  5% {
    -webkit-transform: translateY(-50px) rotate(-5deg) scale(1.2);
            transform: translateY(-50px) rotate(-5deg) scale(1.2);
    opacity: 1;
  }
  20% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    opacity: 0;
  }
}
@keyframes move_cry {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    opacity: 0;
  }
  5% {
    -webkit-transform: translateY(-50px) rotate(-5deg) scale(1.2);
            transform: translateY(-50px) rotate(-5deg) scale(1.2);
    opacity: 1;
  }
  20% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    opacity: 0;
  }
}
/* --- Wrapper Moving --- */
@-webkit-keyframes position_wrapper {
  0% {
    -webkit-transform: translateY(-50%) translatex(calc(0vw - 400px));
            transform: translateY(-50%) translatex(calc(0vw - 400px));
  }
  50% {
    -webkit-transform: translateY(-50%) translatex(calc(100vw + 100px));
            transform: translateY(-50%) translatex(calc(100vw + 100px));
  }
  51% {
    -webkit-transform: translateY(-50%) translatex(calc(100vw + 100px)) scalex(-1);
            transform: translateY(-50%) translatex(calc(100vw + 100px)) scalex(-1);
  }
  100% {
    -webkit-transform: translateY(-50%) translatex(calc(0vw - 400px)) scalex(-1);
            transform: translateY(-50%) translatex(calc(0vw - 400px)) scalex(-1);
  }
}
@keyframes position_wrapper {
  0% {
    -webkit-transform: translateY(-50%) translatex(calc(0vw - 400px));
            transform: translateY(-50%) translatex(calc(0vw - 400px));
  }
  50% {
    -webkit-transform: translateY(-50%) translatex(calc(100vw + 100px));
            transform: translateY(-50%) translatex(calc(100vw + 100px));
  }
  51% {
    -webkit-transform: translateY(-50%) translatex(calc(100vw + 100px)) scalex(-1);
            transform: translateY(-50%) translatex(calc(100vw + 100px)) scalex(-1);
  }
  100% {
    -webkit-transform: translateY(-50%) translatex(calc(0vw - 400px)) scalex(-1);
            transform: translateY(-50%) translatex(calc(0vw - 400px)) scalex(-1);
  }
}
/* ----------------------------------------------- */
/* ----------------------------------------------- */
